import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {File} from "../../shared/models/file";
import {UploadRequest} from "../../shared/models/upload-request";
import {UploadRequestData} from "../../shared/models/upload-request-data";

@Injectable({
    providedIn: 'root'
})
export class FileManagerService {
    // Private
    private _file: BehaviorSubject<File | null> = new BehaviorSubject(null);

    private _files: BehaviorSubject<File[]> = new BehaviorSubject(null);

    private _request: BehaviorSubject<UploadRequest | null> = new BehaviorSubject(null);

    private _requests: BehaviorSubject<UploadRequest[]> = new BehaviorSubject(null);

    private _filesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);


    /**
     * Constructor
     */
    constructor(private _http: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for files
     */
    get files$(): Observable<File[]> {
        return this._files.asObservable();
    }

    set files(value: File[]) {
        this._files.next(value);
    }

    /**
     * Getter for files
     */
    get requests$(): Observable<UploadRequest[]> {
        return this._requests.asObservable();
    }

    set requests(value: UploadRequest[]) {
        this._requests.next(value);
    }

    addFile(value: File) {
        this._files.next([...this._files.getValue(), value]);
    }

    addRequest(value: UploadRequest) {
        this._requests.next([...this._requests.getValue(), value]);
    }

    /**
     * Getter for item
     */
    get file$(): Observable<File> {
        return this._file.asObservable();
    }

    set file(value: File) {
        this._file.next(value);
    }

    /**
     * Getter for item
     */
    get request$(): Observable<UploadRequest> {
        return this._request.asObservable();
    }

    set request(value: UploadRequest) {
        this._request.next(value);
    }


    get filesLoaded$(): Observable<boolean> {
        return this._filesLoaded.asObservable();
    }

    set filesLoaded(value: boolean) {
        this._filesLoaded.next(value)
    }
}
