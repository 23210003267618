import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of, switchMap} from "rxjs";
import {AuthService} from "@auth0/auth0-angular";

/**
 * This guard protects the /sign-up and /confirm routes. If the user is logged in he shouldn't be able to access them.
 */
@Injectable({providedIn: 'root'})
export class NoAuthRoutesGuard implements CanActivate {

    constructor(private _router: Router,
                private _authService: AuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        // Check the authentication status
        return this._authService.isAuthenticated$.pipe(
            switchMap((isAuthenticated: boolean) => {

                // If the user is authenticated...
                if (isAuthenticated) {

                    // Redirect to the root
                    this._router.navigate(['/']);

                    // Prevent the access
                    return of(false);
                }

                // Allow the access
                return of(true);
            })
        )
    }
}
