import {RenderImage} from "./render-image";

export class Rendering {
    private _title: string;
    private _description: string;
    private _token: string;
    private _createdAt: string;
    private _updatedAt: string;
    private _trashedAt: string;
    private _renderImages: RenderImage[];
    private _previewImageUrl: string;
    private _thumbImageUrl: string;
    private _downloadUrl: string;

    constructor(json: any) {
        this.title = json.title;
        this.description = json.description;
        this.token = json.token;
        this.createdAt = json.created_at;
        this.updatedAt = json.updated_at;
        this.trashedAt = json.trashed_at;
        this.renderImages = json.render_images;
    }


    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    set createdAt(value: string) {
        this._createdAt = value;
    }

    get updatedAt(): string {
        return this._updatedAt;
    }

    set updatedAt(value: string) {
        this._updatedAt = value;
    }

    get trashedAt(): string {
        return this._trashedAt;
    }

    set trashedAt(value: string) {
        this._trashedAt = value;
    }


    get renderImages(): RenderImage[] {
        return this._renderImages;
    }

    set renderImages(value: any[]) {
        let renderImages: RenderImage[] = [];
        for (const valueElement of value) {
            renderImages.push(new RenderImage(valueElement))
        }
        this._renderImages = renderImages;
    }


    get previewImageUrl(): string {
        const renderImage: RenderImage = this.renderImages.find((el: RenderImage) => el.type === 'source');

        if (renderImage) {
            return renderImage.previewUrl
        }
        return null;
    }

    set previewImageUrl(value: string) {
        this._previewImageUrl = value;
    }

    get thumbImageUrl(): string {
        const renderImage: RenderImage = this.renderImages.find((el: RenderImage) => el.type === 'source');

        if (renderImage) {
            return renderImage.thumbUrl
        }
        return null;
    }

    set thumbImageUrl(value: string) {
        this._thumbImageUrl = value;
    }


    get downloadUrl(): string {
        const renderImage: RenderImage = this.renderImages.find((el: RenderImage) => el.type === 'source');

        if (renderImage) {
            return renderImage.downloadUrl
        }
        return null;
    }

    set downloadUrl(value: string) {
        this._downloadUrl = value;
    }
}
