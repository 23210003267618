export class RenderImage {
    private _token: string;
    private _resolution: string;
    private _size: string;
    private _type: string;
    private _downloadUrl: string;
    private _thumbUrl: string;
    private _previewUrl: string
    constructor(json: any) {
        this.token = json.token;
        this.type = json.type;
        this.previewUrl = json.preview_url;
        this.downloadUrl = json.download_url;
        this.thumbUrl = json.thumb_url;
        this.size = json.size;
        this.resolution = json.resolution;
    }


    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }

    get resolution(): string {
        return this._resolution;
    }

    set resolution(value: string) {
        this._resolution = value;
    }

    get size(): string {
        return this._size;
    }

    set size(value: string) {
        this._size = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get downloadUrl(): string {
        return this._downloadUrl;
    }

    set downloadUrl(value: string) {
        this._downloadUrl = value;
    }

    get thumbUrl(): string {
        return this._thumbUrl;
    }

    set thumbUrl(value: string) {
        this._thumbUrl = value;
    }

    get previewUrl(): string {
        return this._previewUrl;
    }

    set previewUrl(value: string) {
        this._previewUrl = value;
    }
}
