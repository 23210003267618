import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminComponent} from './admin.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";


@NgModule({
    declarations: [
        AdminComponent
    ],
    exports: [
        AdminComponent
    ],
    imports: [
        CommonModule,
        MatSlideToggleModule
    ]
})
export class AdminModule {
}
