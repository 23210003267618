import {Injectable} from "@angular/core";
import {FuseMockApiService} from "../../../../@fuse/lib/mock-api";
import {FileInfo} from "../../../shared/models/file-info";

@Injectable({providedIn: 'root'})
export class UploadMockApi {

    constructor(private _fuseMockApiService: FuseMockApiService) {
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // @ Upload - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/common/upload-all', 10000)
            .reply(({request}) => {

                const body: FileInfo[] = request.body;

                console.log(body)







                // Return the response

                return [200, {test: "test"}];
            });

        this._fuseMockApiService
            .onPost('api/common/upload', 10000)
            .reply(({request}) => {

                const body: FileInfo = request.body;

                console.log(body)







                // Return the response

                return [200, {test: "test"}];
            });
    }

}
