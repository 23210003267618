import {Injectable} from "@angular/core";
import {FuseMockApiService} from "../../../../@fuse/lib/mock-api";
import {FileInfo} from "../../../shared/models/file-info";

@Injectable({providedIn: 'root'})
export class PeekingServiceMockApi {

    constructor(private _fuseMockApiService: FuseMockApiService) {
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // @ PeekingService - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/common/peeking-service', 5000)
            .reply(({request}) => {

                const body: FileInfo[] = request.body;

                console.log(body)
                // // Update the user mock-api
                // this._user = assign({}, this._user, user);

                // Return the response

                if (body[0].name.includes('png')) {
                    return [400, {status: 'error', error: {status: 'error', error: "Filetype not supported."}}];
                }


                if (body[0].size > 100000) {
                    return [200, {status: 'success', warn: "File is too big. The upload might take a while."}]
                }


                return [200, {status: 'success'}]


            });
    }

}
