import {UploadRequestData} from "./upload-request-data";

export class UploadRequest extends UploadRequestData {
    private _created_at: string;
    private _owner: string;
    private _status: string;
    private _token: string;
    private _trashed_at: string;
    private _updated_at: string;
    private _workspace: string;


    constructor(json: any) {
        super(json);
        this._created_at = json.created_at;
        this._owner = json.owner;
        this._trashed_at = json.trashed_at;
        this._updated_at = json.updated_at;
        this._workspace = json.workspace;
        this._token = json.token;
        this._status = json.status;

    }


    get created_at(): string {
        return this._created_at;
    }

    set created_at(value: string) {
        this._created_at = value;
    }

    get owner(): string {
        return this._owner;
    }

    set owner(value: string) {
        this._owner = value;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }

    get trashed_at(): string {
        return this._trashed_at;
    }

    set trashed_at(value: string) {
        this._trashed_at = value;
    }

    get updated_at(): string {
        return this._updated_at;
    }

    set updated_at(value: string) {
        this._updated_at = value;
    }

    get workspace(): string {
        return this._workspace;
    }

    set workspace(value: string) {
        this._workspace = value;
    }

}
