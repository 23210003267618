import {FileInfo} from "./file-info";

export class File extends FileInfo {
    private _status: string;
    private _token: string;
    private _downloadUrl: string;
    private _createdBy: string;
    private _createdAt: string;
    private _trashedAt: string;

    constructor(name: string, size: number, status?: string, token?: string, downloadUrl?: string, createdBy?: string, createdAt?: string, trashedAt?: string) {
        super(name, size);
        this.status = status;
        this.token = token;
        this.downloadUrl = downloadUrl
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.trashedAt = trashedAt;
    }

    get trashedAt(): string {
        return this._trashedAt;
    }

    set trashedAt(value: string) {
        this._trashedAt = value;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    set createdAt(value: string) {
        this._createdAt = value;
    }

    get createdBy(): string {
        return this._createdBy;
    }

    set createdBy(value: string) {
        this._createdBy = value;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    get downloadUrl(): string {
        return this._downloadUrl;
    }

    set downloadUrl(value: string) {
        this._downloadUrl = value;
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }


}
