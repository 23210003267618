import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ApiService} from '../../shared/services/api.service';
import {catchError, map, of, switchMap} from 'rxjs';
import * as SmartvuActions from './smartvus.actions';
import {NavigationBuilderService} from "../../shared/services/navigation-builder.service";
import {SharedService} from "../../shared/services/shared.service";

@Injectable()
export class SmartvusEffects {
    constructor(private actions$: Actions<any>, private apiService: ApiService, private _navigationBuilderService: NavigationBuilderService, private _sharedService: SharedService) {
    }

    fetchSmartvus$ = createEffect(() =>
        this.actions$.pipe(
            // you can pass in multiple actions here that will trigger the same effect
            ofType(SmartvuActions.appLoaded.type),
            switchMap((payload) =>
                this.apiService.allSmartVus(payload?.workspaceToken, payload?.disableSpacesCheck).pipe(
                    map(({smartVus}) => {
                            //const smartVusCategory = this._navigationBuilderService.getNavigationCategoryById('smartvus');
                            //this._navigationBuilderService.updateCategoryBadgeNumbers(smartVus, smartVusCategory);
                            this._sharedService.isLoading = false;
                            return SmartvuActions.fetchSmartVusSuccess({smartVus: smartVus})
                        }
                    ),
                    catchError((error) => {
                            this._sharedService.isLoading = false;
                            return of(SmartvuActions.fetchSmartVusFailed({error: error}))
                        }
                    )
                )
            )
        )
    );
}
