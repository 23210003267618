import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FuseNavigationModule } from '@fuse/components/navigation/navigation.module';
import {SidebarComponent} from "./sidebar.component";
import {CommonModule} from "@angular/common";

@NgModule({
    declarations: [
        SidebarComponent
    ],
    imports     : [
        RouterModule.forChild([]),
        MatIconModule,
        MatProgressBarModule,
        FuseNavigationModule,
        CommonModule
    ],
    exports     : [
        SidebarComponent
    ]
})
export class SidebarModule
{
}
