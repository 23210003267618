<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <section class="header" id="header">
        <img
                src="assets/images/logo/vuframe-logo-shadow.png"
                class="image"
        />
        <!--        <div class="header-title-container flex flex-col">-->
        <!--            <span class="header-title">SmartVu<span style="color: #b4b4b4;">&reg;</span></span>-->
        <!--            <small class="vuframe-text">BY VUFRAME</small>-->
        <!--        </div>-->
    </section>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <ng-container *transloco="let t">
        <footer class="footer" id="footer">
            <ul>
                <li><a href="https://smartvu.io">SmartVu&reg;</a></li>
                <li><a>Aura3D&reg;</a></li>
                <li><a href="https://vuframe.com">vuframe.com</a></li>
                <li><a href="{{t('cookie-policy-link')}}" target="_blank">Cookie Policy</a></li>
                <li><a href="{{t('imprint-link')}}" target="_blank">Imprint</a></li>
                <li><a href="{{t('privacy-policy-link')}}" target="_blank">Privacy Policy</a></li>
            </ul>
            <p class="vuframe-text">
                Copyright 2023 Vuframe GmbH - All rights reserved.
            </p>
        </footer>
    </ng-container>

</div>
