import {Component} from '@angular/core';
import {appLoaded} from "./store/smartvus";
import {Store} from "@ngrx/store";
import {ApiService} from "./shared/services/api.service";
import {filter, mergeMap, of, switchMap, take} from "rxjs";
import {SharedService} from "./shared/services/shared.service";
import {AuthService, User} from "@auth0/auth0-angular";
import {ChildActivationEnd, Router} from "@angular/router";
import Bugsnag from "@bugsnag/js";
import Logger from "./shared/services/logger";
import {environment} from "../environments/environment";
import {CryptoJsService} from "./shared/services/crypto-js.service";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    readonly environment = environment;


    /**
     * Constructor
     */
    constructor(private _store: Store,
                private _apiService: ApiService,
                private _sharedService: SharedService,
                private _authService: AuthService,
                private _router: Router,
                private _cryptoJsService: CryptoJsService
    ) {
        this._router.events.pipe(
            filter(event => event instanceof ChildActivationEnd),
            take(1)
        ).subscribe((response: ChildActivationEnd) => {
            this._authService.user$.subscribe(user => {
                    if (user) {
                        Bugsnag.setUser(user.sub, user.email, user.name);
                        Bugsnag.addMetadata('user', {
                            auth0_id: user.sub,
                            name: user.name,
                            email: user.email
                        })

                        if (user.email_verified) {
                            this._store.dispatch(appLoaded({workspaceToken: response.snapshot.params?.workspaceToken}));

                            this._sharedService.currentWorkspaceObject$.pipe(
                                switchMap(
                                    (res) => {
                                        if (res) {
                                            return this._apiService.getGroupsOfSpace()
                                        }
                                        return of(null)
                                    }
                                )
                            ).subscribe(groups => {
                                if (groups) {
                                    this._sharedService.setGroups(groups);
                                }
                            })
                        }

                    }
                }
            )
        })


    }

    ngOnInit(): void {
        Logger.info(`Vuframe® Dashboard v${environment.version} - Environment: ${environment.stage.toUpperCase()}`);

        // re-login the user when the SDK throws a `login_required` error.
        this._authService.error$
            .pipe(
                filter((e: any) => e.error === 'login_required'),
                mergeMap(() => this._authService.loginWithRedirect())
            ).subscribe();
    }
}
