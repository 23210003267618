import Bugsnag from "@bugsnag/js";

export default abstract class Logger {
    public static readonly LOG_PREFIX: string = '[Vuframe® Dashboard]'

    public static info(...args: any[]): void {
        var args2 = Array.prototype.slice.call(args)
        args2.unshift(Logger.LOG_PREFIX)
        console.log.apply(console, args2)
    }

    public static error(...args: any[]): void {
        var args2 = Array.prototype.slice.call(args)
        args2.unshift(Logger.LOG_PREFIX)
        console.error.apply(console, args2)
    }

    public static reportError(message: string, error: any): void {
        Logger.error(message, error);
        Bugsnag.notify(error);
    }
}
