import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {FuseNavigationService, FuseVerticalNavigationComponent} from "../../../../@fuse/components/navigation";
import {NavigationBuilderService} from "../../services/navigation-builder.service";
import {TranslocoService} from "@ngneat/transloco";
import {SharedService} from "../../services/shared.service";
import {take} from "rxjs";
import {NavigationContentIDs} from "../../enums/navigations.enum";
import {FileManagerService} from "../../../modules/file-manager/file-manager.service";

/**
 * Reusable sidebar component implementing the fuse-navigation.
 */
@Component({
    selector: 'custom-sidebar',
    template: `
        <div class="mt-6">
            <!-- Add any extra content that might be supplied with the component -->
            <div class="extra-content">
                <ng-content></ng-content>
            </div>

            <ng-container *ngIf="useFuseNavigation">
                <!-- Fixed sidebar -->
                <fuse-vertical-navigation
                    [appearance]="'default'"
                    [inner]="true"
                    [mode]="'side'"
                    [name]="name"
                    [opened]="true"></fuse-vertical-navigation>

            </ng-container>


        </div>
    `,
    styles: [
        `
            demo-sidebar fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
                box-shadow: none !important;
            }

            .extra-content {
                margin: 0 12px;
            }
        `
    ],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements AfterViewInit {
    @Input() name: string;
    @Input() useFuseNavigation: boolean;

    /**
     * The id of the initial sub-navigation which should be loaded
     */
    @Input() contentId: string;


    /**
     * Constructor
     */
    constructor(private _fuseNavigationService: FuseNavigationService,
                private _navigationBuilderService: NavigationBuilderService,
                private _translocoService: TranslocoService,
                private _sharedService: SharedService,
                private _fileManagerService: FileManagerService
    ) {
    }

    ngAfterViewInit() {
        this._translocoService.langChanges$.subscribe(lang => {
            this.updateNavigation(lang);
        });

    }

    /**
     * This method updates the sub navigation when the language changes.
     * @param lang
     * @private
     */
    private updateNavigation(lang: string): void {

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(this.name);

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }


        // get the current sub navigation id and translate its content
        this._navigationBuilderService.activeNavigationContentId$
            .pipe(take(1))
            .subscribe(activeContentId => {

                // check if contentId is not null, if so than take the initial contentId
                const navId = activeContentId ? activeContentId : this.contentId;

                navComponent.navigation = this._navigationBuilderService.buildNavigation(lang, navId);

                switch (navId) {
                    case NavigationContentIDs.smartvus:
                        this._navigationBuilderService.updateSmartVusNavBadgeTitles();
                        break;
                    case NavigationContentIDs.shares:
                        this._navigationBuilderService.updateSharedContentBadgeTitles();
                        break;

                    case NavigationContentIDs.embeds:
                        this._navigationBuilderService.updateEmbedContentBadgeTitles();
                        break;
                }


                this._navigationBuilderService.updateUploadsBadgeTitles()


                this._navigationBuilderService.refreshNavigation(this.name);
            })

    }
}
