import {Component} from "@angular/core";
import {ApiService} from "../services/api.service";
import {Router} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";
import {SharedService} from "../services/shared.service";

@Component({
    selector: 'callback',
    template: ''
})
export class CallbackComponent {

    constructor(private _apiService: ApiService,
                private _router: Router,
                private _authService: AuthService, private _sharedService: SharedService) {
    }

    ngOnInit() {}
}
