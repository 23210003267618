<div class="flex gap-4">
    <mat-form-field class="flex-auto gt-xs:pr-3" subscriptSizing="dynamic">
        <mat-select placeholder="Workspaces..." [(ngModel)]="selectedItem" [formControl]="issue" (selectionChange)="onSelectionChange($event)"
                    [multiple]="isMultiselect">
            <mat-option *ngFor="let item of menuItems"
                        #titleElement
                        [matTooltip]="item.title"
                        [matTooltipDisabled]="disableTooltip(titleElement)"
                        [value]="item">{{item.title}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
