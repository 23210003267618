export enum Navigations {
    mainNavigation = 'mainNavigation',
    subNavigation = 'subNavigation',
    dummyNavigation = 'dummyNavigation'
}

/**
 * Here we store the id's of the sub-navigations
 */
export enum NavigationContentIDs {
    main = 'mainNavigation',
    smartvus = 'smartvus',
    showrooms = 'showrooms',
    catalogs = 'catalogs',
    shares = 'shares',
    embeds = 'embeds',
    dummy = 'dummy'
}
