import {Route} from '@angular/router';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';
import {AuthGuard} from "@auth0/auth0-angular";
import {CallbackComponent} from "./shared/components/callback.component";
import {CheckProfileGuard} from "./shared/guards/check-profile.guard";
import {NoAuthRoutesGuard} from "./shared/guards/no-auth-routes.guard";
import {RedirectComponent} from "./shared/components/redirect/redirect.component";
import {OnboardingGuard} from "./shared/guards/onboarding.guard";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [


    // Redirect empty path to '/smartvus'
    {path: '', pathMatch: 'full', redirectTo: 'redirect'},

    {path: 'callback', component: CallbackComponent},


    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'signup'
        },
        children: [
            // Sign Up is disabled for now.

            // {
            //     path: 'sign-up',
            //     loadChildren: () => import('app/modules/sign-up/sign-up.module').then(m => m.AuthSignUpModule),
            //     canActivate: [NoAuthRoutesGuard]
            // },
            {
                path: 'confirm',
                loadChildren: () => import('app/modules/confirmation/confirmation.module').then(m => m.ConfirmationModule),
                canActivate: [NoAuthRoutesGuard]
            },

        ]
    },

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'blocked',
                loadChildren: () => import('app/modules/blocked/blocked.module').then(m => m.BlockedModule),
                title: 'Blocked - dashboard.vuframe.com'
            },
            {
                path: 'onboarding',
                loadChildren: () => import('app/modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
                canActivate: [OnboardingGuard],
                title: 'Onboarding - dashboard.vuframe.com'
            },
            {
                path: 'setup',
                loadChildren: () => import('app/modules/setup/setup.module').then(m => m.SetupModule),
                title: 'Setup - dashboard.vuframe.com'
            },
            {
                path: 'error',
                loadChildren: () => import('app/modules/error-page/error-page.module').then(m => m.ErrorPageModule)
            },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard, CheckProfileGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'redirect',
                loadChildren: () => import('app/shared/components/redirect/redirect.module').then(m => m.RedirectModule)
            },
            {
                path: 'submit',
                loadChildren: () => import('app/modules/pipedrive-submit/pipedrive-submit.module').then(m => m.PipedriveSubmitModule),
                title: 'Submit - dashboard.vuframe.com'
            },
            {
                path: 'tutorial',
                loadChildren: () => import('app/modules/tutorial/tutorial.module').then(m => m.TutorialModule)

            },
            {
                path: 'workspaces',
                loadChildren: () => import('app/modules/workspaces/workspaces.module').then(m => m.WorkspacesModule)
            },
            {
                path: 'user/settings',
                loadChildren: () => import('app/layout/common/user/user-settings/user-settings.module').then(m => m.UserSettingsModule)
            },
            {
                path: 'learning-centre',
                loadChildren: () => import('app/modules/learning-centre/learning-centre.module').then(m => m.LearningCentreModule)
            }
        ]
    },
];
