import {NgModule} from "@angular/core";
import {SimpleMenuComponent} from "./simple-menu/simple-menu.component";
import {BaseMenuComponent} from "./base-menu.component";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [
        SimpleMenuComponent,
        BaseMenuComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
    ],
    exports: [
        SimpleMenuComponent
    ]
})
export class MenusModule {

}
