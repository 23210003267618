import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ApiService} from "../services/api.service";
import {catchError, Observable, of, switchMap} from "rxjs";
import {UserProfile} from "../models/user-profile";

/**
 * Guard which checks the status of the user and redirects him to the appropriate route.
 */
@Injectable({providedIn: 'root'})
export class CheckProfileGuard implements CanActivate {

    constructor(private _router: Router, private _apiService: ApiService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._apiService.getUserProfile().pipe(
            switchMap((user: UserProfile) => {

                if (user.blocked) {
                    this._router.navigate(['/blocked']);
                    return of(false);
                }


                if (!user.info || !user.info?.firstname || !user.info?.lastname || !user.info?.company) {
                    this._router.navigate(['/onboarding']);
                    return of(false);
                }

                return of(true);
            }),
            catchError(err => {
                return of(true)
            })
        )
    }
}
