import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, map, Observable, tap} from "rxjs";
import {environment} from "../../../environments/environment";
import {Share} from "../../shared/models/share";
import {Embed} from "../../shared/models/embed";

@Injectable({providedIn: 'root'})
export class SharingAndEmbeddingService {
    private _allProducts: BehaviorSubject<Share[] | Embed[]> = new BehaviorSubject<Share[] | Embed[]>(null);
    allProducts$: Observable<Share[] | Embed[]> = this._allProducts.asObservable();

    constructor(private _http: HttpClient) {
    }

    /**
     * Get all shares or embeds for a workspace.
     * @param workspaceToken
     * @param type - can be share or embed
     */
    getSharesOrEmbedsByWorkspace(workspaceToken: string, type: 'shares' | 'embeds'): Observable<Share[] | Embed[]> {
        return this._http.get<Share[] | Embed[]>(`${environment.apiBaseUrl}/internal/v1/workspaces/${workspaceToken}/${type}`)
            .pipe(
                map(response => {
                    const mappedItems = response.map(item => {
                        if (type === "shares") {
                            return {...item, type: 'share'}
                        }

                        return {...item, type: 'embed'}
                    })
                    this._allProducts.next(mappedItems);
                    return mappedItems;
                })
            );
    }

    /**
     * Get share or embed by code.
     * @param code - embed code
     * @param type - can be share or embed
     */
    getShareOrEmbedByCode(code: string, type: 'shares' | 'embeds'): Observable<Share | Embed> {
        return this._http.get<Share | Embed>(`${environment.apiBaseUrl}/internal/v1/${type}/${code}`);
    }

    /**
     * Get shares or embeds by SmartVu Token.
     * @param smartVuToken - token of smartvu
     * @param type - can be share or embed
     */
    getSharesOrEmbedsByToken(smartVuToken: string, type: 'shares' | 'embeds'): Observable<Share[] | Embed[]> {
        return this._http.get<Share[] | Embed[]>(`${environment.apiBaseUrl}/internal/v1/smartvus/${smartVuToken}/${type}`)
            .pipe(
                map(response => {
                    const mappedItems = response.map(item => {
                        if (type === "shares") {
                            return {...item, type: 'share'}
                        }

                        return {...item, type: 'embed'}
                    })
                    return mappedItems;
                })
            );
        ;
    }

    /**
     * Get Embed Locations by embed code.
     * @param embedCode - embed code
     */
    getLocationsByEmbedCode(embedCode: string): Observable<any> {
        return this._http.get(`${environment.apiBaseUrl}/internal/v1/embeds/${embedCode}/locations`)
    }

    /**
     * Get all Embed Locations for a workspace.
     * @param workspaceToken
     */
    getLocationsByWorkspaceToken(workspaceToken: string): Observable<any> {
        return this._http.get(`${environment.apiBaseUrl}/internal/v1/workspaces/${workspaceToken}/embed_locations`)
    }


}
