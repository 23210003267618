<ng-container *ngIf="authService.user$ | async as user" >
    <!-- Button -->
    <button
        mat-icon-button
        [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user.picture"
            [src]="user.picture">
        <mat-icon
            *ngIf="!showAvatar || !user.picture"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
    </button>

    <mat-menu
        [xPosition]="'before'"
        #userActions="matMenu">
        <ng-container *transloco="let t">
            <button mat-menu-item disabled>
        <span class="flex flex-col leading-none">
            <span>{{t('user-menu.signed-in-as')}}</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item [routerLink]="['/user/settings']">
                <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
                <span>{{t('user-menu.settings')}}</span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <button
                    mat-menu-item
                    (click)="signOut()">
                <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                <span>{{t('user-menu.sign-out')}}</span>
            </button>
        </ng-container>

    </mat-menu>


</ng-container>
