export class UploadRequestData {

    private _title: string
    private _description: string
    private _urls: string[] = [];
    private _files: any;

    constructor(data: any) {
        this._title = data.title;
        this._description = data.description;
        this._files = data.files;
        this.urls = data.urls;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get urls(): any {
        return this._urls;
    }

    set urls(value: any) {

        for (let item of value) {
            if (item) {
                if (!/^https?:\/\/.*/.test(item)) {
                    item = 'https://' + item
                }
                this._urls.push(item)
            }

        }


    }

    get files(): any {
        return this._files;
    }

    set files(value: any) {
        this._files = value;
    }
}
