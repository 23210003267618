import { NgModule } from '@angular/core';
import {FallbackImageDirective} from "./fallback-image.directive";
import {DynamicScriptDirective} from "./dynamic-script.directive";

@NgModule({
    imports: [],
    declarations: [FallbackImageDirective, DynamicScriptDirective],
    exports: [FallbackImageDirective, DynamicScriptDirective]
})
export class DirectivesModule { }
