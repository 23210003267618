import {createAction, props} from '@ngrx/store';
import {SmartVu} from "../../shared/models/smartvu";

export const appLoaded = createAction('[App] App Loaded', props<{workspaceToken?: string; disableSpacesCheck?: boolean}>());

export const fetchSmartVusSuccess = createAction(
    '[Vuframe API] Fetch SmartVus Success',
    props<{ smartVus: SmartVu[] }>()
);

export const fetchSmartVusFailed = createAction(
    '[Vuframe API] Fetch SmartVus Failed',
    props<{ error: any }>()
);

export const cleanState = createAction(
    '[Vuframe API] Clean SmartVus State'
)
