import {BehaviorSubject, Observable} from "rxjs";
import {Rendering} from "../models/rendering";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class CustomStoreService {
    private _cachedRenderings: BehaviorSubject<Rendering[]> = new BehaviorSubject<Rendering[]>([]);
    cachedRenderings$: Observable<Rendering[]> = this._cachedRenderings.asObservable();

    private _cachedWorkspaceRenderings: { [workspaceToken: string]: Rendering[] } = {};

    private _cachedRequestRenderings: { [requestToken: string]: Rendering[] } = {};

    constructor() {
    }

    getCachedRendering(renderingToken: string): Rendering {
        return this._cachedRenderings.getValue().find((rendering: Rendering) => rendering.token === renderingToken);
    }

    cacheRendering(value: Rendering): void {
        this._cachedRenderings.next([...this._cachedRenderings.getValue(), value]);
    }

    cacheWorkspaceRenderings(workspaceToken: string, renderings: Rendering[]): void {
        this._cachedWorkspaceRenderings[workspaceToken] = renderings;
    }


    get cachedWorkspaceRenderings(): { [p: string]: Rendering[] } {
        return this._cachedWorkspaceRenderings;
    }

    cacheRequestRenderings(requestToken: string, renderings: Rendering[]): void {
        this._cachedRequestRenderings[requestToken] = renderings;
    }


    get cachedRequestRenderings(): { [p: string]: Rendering[] } {
        return this._cachedRequestRenderings;
    }
}
