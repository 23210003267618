/* eslint-disable */
export const items = [
    {
        name: "test1.glb",
        extension: "glb",
        size: 1000,
        path: "test1.glb",
        status: 'success'
    },
    {
        name: "test2.c4d",
        extension: "c4d",
        size: 1000,
        path: "test1.c4d",
        status: 'success'
    },
    {
        name: "test3.zip",
        extension: "zip",
        size: 1000,
        path: "test1.zip",
        status: 'failed'
    },
    // {
    //     id         : 'ec07a98d-2e5b-422c-a9b2-b5d1c0e263f5',
    //     folderId   : null,
    //     name       : 'Personal projects',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'March 18, 2020',
    //     modifiedAt : 'March 18, 2020',
    //     size       : '4.3 MB',
    //     type       : 'DOC',
    //     contents   : null,
    //     description: null
    // },
    // {
    //     id         : 'ae908d59-07da-4dd8-aba0-124e50289295',
    //     folderId   : null,
    //     name       : 'Biometric portrait',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'August 29, 2020',
    //     modifiedAt : 'August 29, 2020',
    //     size       : '4.5 MB',
    //     type       : 'JPG',
    //     contents   : null,
    //     description: null
    // },
    // {
    //     id         : '4038a5b6-5b1a-432d-907c-e037aeb817a8',
    //     folderId   : null,
    //     name       : 'Scanned image 20201012-1',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'September 13, 2020',
    //     modifiedAt : 'September 13, 2020',
    //     size       : '7.8 MB',
    //     type       : 'JPG',
    //     contents   : null,
    //     description: null
    // },
    // {
    //     id         : '630d2e9a-d110-47a0-ac03-256073a0f56d',
    //     folderId   : null,
    //     name       : 'Scanned image 20201012-2',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'September 14, 2020',
    //     modifiedAt : 'September 14, 2020',
    //     size       : '7.4 MB',
    //     type       : 'JPG',
    //     contents   : null,
    //     description: null
    // },
    // {
    //     id         : '1417d5ed-b616-4cff-bfab-286677b69d79',
    //     folderId   : null,
    //     name       : 'Prices',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'April 07, 2020',
    //     modifiedAt : 'April 07, 2020',
    //     size       : '2.6 MB',
    //     type       : 'DOC',
    //     contents   : null,
    //     description: null
    // },
    // {
    //     id         : 'bd2817c7-6751-40dc-b252-b6b5634c0689',
    //     folderId   : null,
    //     name       : 'Shopping list',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'March 26, 2021',
    //     modifiedAt : 'March 26, 2021',
    //     size       : '2.1 MB',
    //     type       : 'DOC',
    //     contents   : null,
    //     description: null
    // },
    // {
    //     id         : '14fb47c9-6eeb-4070-919c-07c8133285d1',
    //     folderId   : null,
    //     name       : 'Summer budget',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'June 02, 2020',
    //     modifiedAt : 'June 02, 2020',
    //     size       : '943 KB',
    //     type       : 'XLS',
    //     contents   : null,
    //     description: null
    // },
    //
    // {
    //     id         : '894e8514-03d3-4f5e-bb28-f6c092501fae',
    //     folderId   : 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
    //     name       : 'A personal file',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'June 02, 2020',
    //     modifiedAt : 'June 02, 2020',
    //     size       : '943 KB',
    //     type       : 'XLS',
    //     contents   : null,
    //     description: null
    // },
    // {
    //     id         : '74010810-16cf-441d-a1aa-c9fb620fceea',
    //     folderId   : 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
    //     name       : 'A personal folder',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'November 01, 2021',
    //     modifiedAt : 'November 01, 2021',
    //     size       : '3015 MB',
    //     type       : 'folder',
    //     contents   : '907 files',
    //     description: 'Personal photos; selfies, family, vacation and etc.'
    // },
    // {
    //     id         : 'a8c73e5a-8114-436d-ab54-d900b50b3762',
    //     folderId   : '74010810-16cf-441d-a1aa-c9fb620fceea',
    //     name       : 'A personal file within the personal folder',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'June 02, 2020',
    //     modifiedAt : 'June 02, 2020',
    //     size       : '943 KB',
    //     type       : 'XLS',
    //     contents   : null,
    //     description: null
    // },

    // {
    //     id         : '12d851a8-4f60-473e-8a59-abe4b422ea99',
    //     folderId   : '6da8747f-b474-4c9a-9eba-5ef212285500',
    //     name       : 'Photos file',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'June 02, 2020',
    //     modifiedAt : 'June 02, 2020',
    //     size       : '943 KB',
    //     type       : 'XLS',
    //     contents   : null,
    //     description: null
    // },
    // {
    //     id         : '2836766d-27e1-4f40-a31a-5a8419105e7e',
    //     folderId   : 'ed58add1-45a7-41db-887d-3ca7ee7f2719',
    //     name       : 'Work file',
    //     createdBy  : 'Brian Hughes',
    //     createdAt  : 'June 02, 2020',
    //     modifiedAt : 'June 02, 2020',
    //     size       : '943 KB',
    //     type       : 'XLS',
    //     contents   : null,
    //     description: null
    // }
];
