import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

/**
 * This service contains the business logic for 'admin' and 'user' mode.
 */
@Injectable({providedIn: 'root'})
export class AdminService {
    private _isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isAdmin$: Observable<boolean> = this._isAdmin.asObservable();

    private _adminMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    adminMode$: Observable<boolean> = this._adminMode.asObservable();

    constructor() {
    }

    set isAdmin(value: boolean) {
        this._isAdmin.next(value);
    }

    set adminMode(value: boolean) {
        this._adminMode.next(value);
    }

}
