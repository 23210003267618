import {Component, HostBinding, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {AuthService} from "@auth0/auth0-angular";

@Component({
    selector: 'signup-layout',
    templateUrl: './signup-layout.component.html',
    styleUrls: ['./signup-layout.component.scss'],
    encapsulation:
    ViewEncapsulation.None
})

export class SignupLayoutComponent implements OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @HostBinding('className') componentClass: string;

    /**
     * Constructor
     */
    constructor(public authService: AuthService) {
        this.componentClass = 'bg-white'
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
