import {Action, createReducer, on} from '@ngrx/store';
import * as SmartvusActions from './smartvus.actions';
import {initialState} from './smartvus.state';

const smartvusReducer = createReducer(
    initialState,
    on(SmartvusActions.fetchSmartVusSuccess, (state, {smartVus}) => {
        const newSmartVusArray = [...state.smartvus, ...smartVus];

        return {
            ...state,
            smartvus: newSmartVusArray
        }
    }),
    on(SmartvusActions.cleanState, (state, action) => ({
        ...initialState
    }))
);

export function reducer(state: any | undefined, action: Action) {
    return smartvusReducer(state, action);
}
