import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CallbackComponent} from "./components/callback.component";
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {QrCodeComponent} from "./components/qr-code/qr-code.component";
import {QRCodeModule} from "angularx-qrcode";
import {PipedriveModalComponent} from './components/pipedrive-modal/pipedrive-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {DirectivesModule} from "./directives/directives.module";

@NgModule({
    declarations: [
        CallbackComponent,
        LoadingSpinnerComponent,
        QrCodeComponent,
        PipedriveModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        QRCodeModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        DirectivesModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingSpinnerComponent,
        QrCodeComponent
    ]
})
export class SharedModule {
}
