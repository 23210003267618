import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SmartVu} from "../../shared/models/smartvu";

export const selectSmartvus = createFeatureSelector<any>('smartvus');

export const selectSmartVuItems = createSelector(
    selectSmartvus,
    (state: any) => Object.assign({}, {smartvus: state.smartvus})

);

export const selectSmartVuItem = (props: { token: string }): any =>
    createSelector(selectSmartVuItems, ({smartvus}) => Object.assign({}, smartvus.find((smartvu: SmartVu) => smartvu.token === props.token))
    );

export const selectPresentations = createSelector(
    selectSmartvus,
    ({smartvus}) => smartvus.filter((smartVu: SmartVu) => smartVu.category === null || smartVu.category === 'presentation')

);

export const selectCatalogs = createSelector(
    selectSmartvus,
    ({smartvus}) => smartvus.filter((smartVu: SmartVu) => smartVu.category === 'catalog')
);

export const selectShowrooms = createSelector(
    selectSmartvus,
    ({smartvus}) => smartvus.filter((smartVu: SmartVu) => smartVu.category === 'showroom')
);

export const selectConfigurators = createSelector(
    selectSmartvus,
    ({smartvus}) => smartvus.filter((smartVu: SmartVu) => smartVu.category === 'configurator')
);
