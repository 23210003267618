import {Component, Input, OnInit} from '@angular/core';
import {BaseMenuComponent} from '../base-menu.component';
import {FormControl} from '@angular/forms';
import {MatOption} from "@angular/material/core";
import {Store} from "@ngrx/store";
import {ApiService} from "../../../services/api.service";
import {SharedService} from "../../../services/shared.service";
import {Workspace} from "../../../models/workspace";

@Component({
    selector: 'simple-menu',
    templateUrl: './simple-menu.component.html',
    styleUrls: ['./simple-menu.component.scss'],

})
export class SimpleMenuComponent extends BaseMenuComponent implements OnInit {

    @Input() menuItems: Workspace[];

    issue = new FormControl();

    constructor(protected store: Store,
                protected apiService: ApiService,
                protected sharedService: SharedService) {
        super(store, apiService, sharedService);
    }

    ngOnInit(): void {
    }

    disableTooltip(element: MatOption) {
        return element._getHostElement().firstElementChild.scrollWidth === element._getHostElement().firstElementChild.clientWidth;
    }

}
