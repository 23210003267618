import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {IGroup} from "../interfaces/group.interface";
import {Workspace} from "../models/workspace";

/**
 * The shared service holds observables which can be accessed from each component in the app.
 */
@Injectable({providedIn: 'root'})
export class SharedService {

    private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    isLoading$: Observable<boolean> = this._isLoading.asObservable();

    private _allSpaces: BehaviorSubject<Workspace[]> = new BehaviorSubject<Workspace[]>(null);
    allSpaces$: Observable<Workspace[]> = this._allSpaces.asObservable();

    private _currentWorkspaceObject: BehaviorSubject<Workspace> = new BehaviorSubject<Workspace>(null);
    currentWorkspaceObject$: Observable<Workspace> = this._currentWorkspaceObject.asObservable();

    private _groups: BehaviorSubject<IGroup[]> = new BehaviorSubject<IGroup[]>(null);
    groups$: Observable<IGroup[]> = this._groups.asObservable();

    private _searchValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
    searchValue$: Observable<string> = this._searchValue.asObservable();

    private _currentWorkspaceToken: BehaviorSubject<string> = new BehaviorSubject<string>('');
    currentWorkspaceToken$: Observable<string> = this._currentWorkspaceToken.asObservable();

    constructor() {
    }

    setAllSpaces(value: Workspace[]): void {
        if (value) {
            this._allSpaces.next(value);
        }
    }

    setCurrentWorkspaceObject(workSpaceToken: string): void {
        const allSpaces = this._allSpaces.getValue();
        let workspaceObject: Workspace;
        if (allSpaces && allSpaces.length !== 0) {
            workspaceObject = allSpaces.find(space => space.token === workSpaceToken);
            if (workspaceObject) {
                this._currentWorkspaceObject.next(workspaceObject);
                this._currentWorkspaceToken.next(workspaceObject.token);
            }
        }
    }

    setCurrentWorkspaceToken(token: string): void {
        if(token) {
            this._currentWorkspaceToken.next(token);
        }
    }

    setGroups(value: IGroup[]): void {
        if (value) {
            this._groups.next(value);
        }
    }

    set searchValue(searchValue: string) {
        this._searchValue.next(searchValue);
    }

    set isLoading(value: boolean) {
        this._isLoading.next(value);
    }
}
