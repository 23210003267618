import { IconsMockApi } from 'app/mock-api/ui/icons/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import {PeekingServiceMockApi} from "./common/peeking-service/api";
import {UploadMockApi} from "./common/upload/api";
import {FileManagerMockApi} from "./common/file-manager/api";

export const mockApiServices = [
    IconsMockApi,
    NavigationMockApi,
    UserMockApi,
    PeekingServiceMockApi,
    UploadMockApi,
    FileManagerMockApi
];
