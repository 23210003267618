import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { SharedModule } from 'app/shared/shared.module';
import {LanguagesModule} from "../../common/languages/languages.module";
import {UserModule} from "../../common/user/user.module";
import {SignupLayoutComponent} from "./signup-layout.component";
import {TranslocoModule} from "@ngneat/transloco";

@NgModule({
    declarations: [
        SignupLayoutComponent
    ],
    imports     : [
        RouterModule,
        FuseLoadingBarModule,
        SharedModule,
        LanguagesModule,
        UserModule,
        TranslocoModule
    ],
    exports     : [
        SignupLayoutComponent
    ]
})
export class SignupLayoutModule
{
}
