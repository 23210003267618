import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {AvailableLangs, getBrowserLang, TranslocoService} from '@ngneat/transloco';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {NavigationBuilderService} from "../../../shared/services/navigation-builder.service";
import {SharedService} from "../../../shared/services/shared.service";
import {NavigationContentIDs, Navigations} from "../../../shared/enums/navigations.enum";

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _navigationBuilderService: NavigationBuilderService,
        private _sharedService: SharedService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        this.activeLang = getBrowserLang();
        if (localStorage.getItem('lang')) {
            this.activeLang = localStorage.getItem('lang');
        }

        this.setActiveLang(this.activeLang);

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'en': 'us',
            'de': 'de'
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
        localStorage.setItem('lang', lang);

        // Get the active lang
        this.activeLang = lang;

        // Update the navigation
        this._updateNavigation(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(Navigations.mainNavigation);

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }


        navComponent.navigation = this._navigationBuilderService.buildNavigation(lang, NavigationContentIDs.main);
        navComponent.refresh();
    }


}
